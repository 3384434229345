/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import createGet from '../utils/create-get';

import Layout from '../components/Layout';
import Container from '../components/Container2';
import AspectRatioImg from '../components/AspectRatioImg';
import MarkdownContent from '../components/MarkdownContent';
import headerHospitalsSvg from '../img/headers/header-hospitals.svg';
import grace from '../img/illustrations/grace-ideator.svg';
import gregory from '../img/illustrations/gregory-innovator.svg';
import giselle from '../img/illustrations/giselle-sponsor.svg';
import collaboration from '../img/illustrations/collaboration.svg';
import { Button } from '../components/Buttons';
import List from '../components/List';

const imageMap = { grace, gregory, giselle, collaboration };
const ModuleContent = ({ get, section }) => (
  <div>
    <Styled.h3 sx={{ mt: 0, mb: 7 }}>{get(section, 'title')}</Styled.h3>
    <List rounded>
      {get(section, 'list', []).map((item) => (
        <li>{item}</li>
      ))}
    </List>
    <Styled.h4 sx={{ color: '#6F39D3', my: 5 }}>
      {get(section, 'subtext')}
    </Styled.h4>
    <Button
      to={get(section, 'cta.path')}
      variant="secondary"
      sx={{
        maxWidth: [null, null, '100px'],
        p: 2,
      }}
      outlined
    >
      {get(section, 'cta.label')}
    </Button>
  </div>
);

const ModuleIllustration = ({ get, section }) => (
  <div
    sx={{
      display: ['none', null, 'block'],
      border: 'solid 2px',
      borderColor: '#9B85FB',
      width: 'min-content',
      height: 'min-content',
    }}
  >
    <AspectRatioImg
      src={imageMap[get(section, 'imageKey')]}
      alt=""
      ratio={300 / 280}
      width={'280px'}
    />
    <div
      sx={{
        display: ['none', null, 'flex'],
        justifyContent: 'center',
        py: 2,
        backgroundColor: '#C1C0FF',
      }}
    >
      {get(section, 'imageLabel')}
    </div>
  </div>
);

const HospitalsPageTemplate = ({ get }) => {
  return (
    <Fragment>
      <Container>
        <AspectRatioImg src={headerHospitalsSvg} alt="" ratio={912 / 256} />
        {get('sectionI', []).map((section) => (
          <div sx={{ my: 9 }} key={`section-${get(section, 'title')}`}>
            <Styled.h3>{get(section, 'title')}</Styled.h3>
            <MarkdownContent sx={{ mt: 7 }}>
              {get(section, 'text')}
            </MarkdownContent>
          </div>
        ))}
        <section>
          {get('sectionII', []).map((section, i) => (
            <article sx={{ display: 'flex', gap: 7, my: 13 }} key={i}>
              {i % 2 === 0 ? (
                <Fragment>
                  <ModuleIllustration get={get} section={section} />
                  <ModuleContent get={get} section={section} />
                </Fragment>
              ) : (
                <Fragment>
                  <ModuleContent get={get} section={section} />
                  <ModuleIllustration get={get} section={section} />
                </Fragment>
              )}
            </article>
          ))}
        </section>
      </Container>
    </Fragment>
  );
};
const HospitalsPage = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
  } = data;

  return (
    <Layout
      page={'hospitals'}
      seo={{
        title: frontmatter.pageTitle,
        description: frontmatter.description,
      }}
    >
      <HospitalsPageTemplate get={createGet(frontmatter)} />
    </Layout>
  );
};

HospitalsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default HospitalsPage;

export const pageQuery = graphql`
  query HospitalsPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        description
        imageKey
        text
        sectionI {
          title
          text
        }
        sectionII {
          title
          list
          subtext
          cta {
            label
            path
          }
          imageKey
          imageLabel
        }
      }
    }
  }
`;
